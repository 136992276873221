import {
  PRODUCT_COMMUNITY_DEAL_FRAGMENT,
  PRODUCT_EXTERNAL_OFFER_FRAGMENT,
} from './shared';

export const GET_PRODUCT_DEALS_QUERY = `
    query GetProductDeals(
      $id: ID!
      $sort: SearchProductOffersSort!
      $limit: Int
      $filters: ProductOffersFilter
    ) {
      public {
        productOffers(
          productId: $id
          sort: $sort
          limit: $limit
          filters: $filters
        ) {
          edges {
            ... on ExternalOfferV1 {
              ...ProductExternalOfferFragment
            }
            ... on Offer {
              ...ProductCommunityDealFragment
            }
          }
          pageInfo {
            total
          }
        }
      }
    }
  ${PRODUCT_COMMUNITY_DEAL_FRAGMENT}
  ${PRODUCT_EXTERNAL_OFFER_FRAGMENT}
`;
