import { DEAL_TIMESTAMPS_FRAGMENT } from '../../fragments/deal';

export const HOTTEST_OFFERS_QUERY = `
  query HottestOffersQuery($categorySlug: String, $storeId: ID) {
    public {
      hottestOffers(categorySlug: $categorySlug, storeId: $storeId) {
        id
        discountFixed
        discountPercentage
        freeShipping
        image {
          id
          url(height: 122)
        }
        price
        sourceUrl
        status
        temperature
        title
        commentCount
        kind
        timestamps {
          ...DealTimestampsFragment
        }
        store {
          id
          name
          slug
          url
          image {
            id
            url(height: 122)
          }
        }
      }
    }
  }

  ${DEAL_TIMESTAMPS_FRAGMENT}
`;
