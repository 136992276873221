import { CASHBACK_FRAGMENT_OFFER } from '../../fragments/cashback';
import { DEAL_TIMESTAMPS_FRAGMENT } from '../../fragments/deal';

export const getRecommendationsFromOfferQueryText = (
  privateQuery?: boolean
) => `
    query RecommendationsFromOfferQuery($offerId: ID!, $limit: Int) {
        ${privateQuery ? 'me' : 'public'} {
            recommendationsFromOffer(offerId: $offerId, limit: $limit) {
                edges {
                    id
                    discountFixed
                    discountPercentage
                    freeShipping
                    image {
                        id
                        url(height: 122)
                    }
                    price
                    sourceUrl
                    status
                    temperature
                    title
                    commentCount
                    kind
                    timestamps {
                        ...DealTimestampsFragment
                    }

                    ...CashbackFragmentOffer
                    
                    store {
                        id
                        name
                        slug
                        url
                        image {
                            id
                            url(height: 122)
                        }
                    }
            }
            recommendationId
            }
        }
    }
     ${CASHBACK_FRAGMENT_OFFER}
    ${DEAL_TIMESTAMPS_FRAGMENT}
`;
