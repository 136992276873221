import { useCallback, useEffect, useRef } from 'react';
import { GooglePubTag } from '../../../GooglePubAdsScript';
import {
  REFRESH_DEFAULT_INTERVAL,
  SlotCode,
  TargetingKey,
} from '../../constants';
import { GoogleTagEventHandler } from '../../types';

export type UseRefreshAdParams = {
  slotCode: SlotCode;
  pubAds?: GooglePubTag;
  preventRefresh?: boolean;
};

export function useRefreshAd({
  slotCode,
  pubAds,
  preventRefresh,
}: UseRefreshAdParams) {
  const refreshTimeoutId = useRef(0);

  const onImpressionViewed = useCallback<GoogleTagEventHandler>(
    (event) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      if (!pubAds || event.slot.getAdUnitPath() !== slotCode) return () => {};
      clearTimeout(refreshTimeoutId.current);

      refreshTimeoutId.current = window.setTimeout(() => {
        const nextRefreshCount =
          Number(event.slot.getTargeting(TargetingKey.RefreshCount)[0] || '0') +
          1;

        event.slot.setTargeting(
          TargetingKey.RefreshCount,
          nextRefreshCount.toString()
        );

        pubAds?.pubads().refresh([event.slot]);
      }, REFRESH_DEFAULT_INTERVAL);

      return () => {
        clearTimeout(refreshTimeoutId.current);
      };
    },
    [pubAds, slotCode]
  );

  useEffect(() => {
    if (!pubAds || preventRefresh) return undefined;

    pubAds.cmd.push(() => {
      pubAds
        ?.pubads()
        .addEventListener('impressionViewable', onImpressionViewed);
    });

    return () => {
      pubAds.cmd.push(() => {
        pubAds
          ?.pubads()
          .removeEventListener('impressionViewable', onImpressionViewed);
      });
    };
  }, [onImpressionViewed, preventRefresh, pubAds]);
}
