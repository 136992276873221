import { Deal, OfferStatus } from '../../../domain/entities/Offer';
import { createNewEndpoint } from '../core/endpoint';
import { GraphQlMethods } from '../core/fetch';
import { PreventUnauthenticatedGuard } from '../core/guards/preventUnauthenticated';
import { ResponseResult } from '../core/request';
import { DealsActionType } from './actionTypes';
import { DEAL_DETAIL_VISIT_MUTATION } from './mutations/dealDetailVisit';
import { DELETE_OFFER_MUTATION } from './mutations/deleteOffer';
import { EXPIRE_OFFER_MUTATION } from './mutations/expireOffer';
import { REPORT_OFFER_MUTATION } from './mutations/reportOffer';
import { UPDATE_LAST_VISITED_DEAL_MUTATION } from './mutations/updateLastVisited';
import {
  SEND_TO_TELEGRAM_MUTATION,
  UPDATE_OFFER_EXPIRE_LOCK_MUTATION,
  UPDATE_OFFER_IS_PICKED_MUTATION,
  UPDATE_OFFER_IS_TRACKED_MUTATION,
  UPDATE_OFFER_LOCKED_COMMENTS_MUTATION,
  UPDATE_OFFER_STATUS_MUTATION,
} from './mutations/updateOfferStatus';
import { DUPLICATED_DEALS_QUERY } from './queries/duplicatedDealsQuery';
import { DEAL_TO_EDIT_QUERY } from './queries/getDealToEdit';
import { HOTTEST_OFFERS_QUERY } from './queries/hottestOffersQuery';
import { LAST_VISITED_DEAL_QUERY } from './queries/lastVisitedQuery';
import { OFFER_QUERY } from './queries/offerQuery';
import { getRecommendationsFromOfferQueryText } from './queries/recommendationsFromOfferQuery';
import {
  DealDetailVisitResponse,
  DealDetailVisitVariables,
} from './types/DealDetailVisit';
import {
  DuplicatedDealsResponse,
  DuplicatedDealsVariables,
} from './types/DuplicatedDeals';
import { GetDealResponse, GetDealVariables } from './types/GetDeal';
import {
  GetDealToEditResponse,
  GetDealToEditVariables,
} from './types/GetDealToEdit';
import {
  HottestOffersQueryResponse,
  RecommendationsHottestOffersCache,
  RecommendationsHottestOffersQueryResponse,
  RecommendationsPublicHottestOffersQueryResponse,
} from './types/HottestDeals';
import {
  LastVisitedResponse,
  LastVisitedVariables,
} from './types/LastVisitedResponse';
import {
  DeleteOfferResponse,
  ExpireOfferResponse,
  OfferIsPickedResponse,
  OfferIsTrackedResponse,
  OfferLockedCommentsResponse,
  ReportOfferParam,
  ReportOfferResponse,
  SendOfferResponse,
  UpdateOfferExpireLockParam,
  UpdateOfferExpireLockResponse,
  UpdateOfferIsPickedParam,
  UpdateOfferIsTrackedParam,
  UpdateOfferLockedComentsParam,
  UpdateOfferStatusResponse,
} from './types/OfferOptions';

export const getDeal = createNewEndpoint<
  GetDealResponse,
  GetDealVariables,
  Deal,
  Deal | undefined
>({
  id: DealsActionType.GetDeal,
  query: OFFER_QUERY,
  method: GraphQlMethods.Get,
  transform: (result) => result.public.offer,
  cacheTransform: (result) => result.data,
});

export const getLastVisited = createNewEndpoint<
  LastVisitedResponse,
  { offerId: string },
  string,
  string
>({
  id: DealsActionType.GetLastVisited,
  query: LAST_VISITED_DEAL_QUERY,
  method: GraphQlMethods.Get,
  guards: [PreventUnauthenticatedGuard],
  transform: (response) => response.me.userLastVisitedAt,
  cacheTransform: ({ data }) => data,
});

export const getRecommendedPrivateHottestDeals = createNewEndpoint<
  RecommendationsHottestOffersQueryResponse,
  { offerId: string; limit: number },
  RecommendationsHottestOffersQueryResponse['me']['recommendationsFromOffer'],
  RecommendationsHottestOffersCache
>({
  id: DealsActionType.GetRecommendationsHottestDeals,
  query: getRecommendationsFromOfferQueryText(true),
  method: GraphQlMethods.Get,
  transform: (response) => response.me.recommendationsFromOffer,
  cacheTransform: (response) => ({
    edges: response?.data?.edges,
    recommendationId: response?.data?.recommendationId,
    loading: response.loading,
  }),
});

export const getRecommendedPublicHottestDeals = createNewEndpoint<
  RecommendationsPublicHottestOffersQueryResponse,
  { offerId: string; limit: number },
  RecommendationsPublicHottestOffersQueryResponse['public']['recommendationsFromOffer'],
  RecommendationsHottestOffersCache
>({
  id: DealsActionType.GetRecommendationsPublicHottestDeals,
  query: getRecommendationsFromOfferQueryText(false),
  method: GraphQlMethods.Get,
  transform: (response) => response.public.recommendationsFromOffer,
  cacheTransform: (response) => {
    getRecommendedPrivateHottestDeals.cache$.next({
      edges: response?.data?.edges,
      recommendationId: response?.data?.recommendationId,
      loading: response.loading,
    });
    return {
      edges: response?.data?.edges,
      recommendationId: response?.data?.recommendationId,
      loading: response.loading,
    };
  },
});

export const updateLastVisited = createNewEndpoint<
  LastVisitedResponse,
  LastVisitedVariables,
  string
>({
  id: DealsActionType.PostUpdateLastVisited,
  query: UPDATE_LAST_VISITED_DEAL_MUTATION,
  method: GraphQlMethods.Post,
  guards: [PreventUnauthenticatedGuard],
  transform: (response) => {
    getLastVisited.cache$.next(response.me.userLastVisitedAt);
    return response.me.userLastVisitedAt;
  },
});

export const getDealToEdit = createNewEndpoint<
  GetDealToEditResponse,
  GetDealToEditVariables,
  Deal | undefined
>({
  id: DealsActionType.GetDealToEdit,
  query: DEAL_TO_EDIT_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response?.public.deal,
});

export const dealDetailVisit = createNewEndpoint<
  DealDetailVisitResponse,
  DealDetailVisitVariables,
  boolean
>({
  id: DealsActionType.PostDealDetailVisit,
  query: DEAL_DETAIL_VISIT_MUTATION,
  method: GraphQlMethods.Post,
  transform: (response) => response.public.dealDetailVisit,
});

const updateDealInterceptor = ({
  incoming,
}: {
  incoming: ResponseResult<Partial<Deal>, unknown>;
}) => {
  if (incoming.loading || !incoming.data) return;
  const dealCache = getDeal.cache$.value;
  if (incoming.data.id === dealCache?.id) {
    getDeal.cache$.next({ ...dealCache, ...(incoming.data as Deal) });
  }
};

export const expireOffer = createNewEndpoint<
  ExpireOfferResponse,
  { id: string },
  ExpireOfferResponse['me']['expireOffer']
>({
  id: DealsActionType.PostExpireOffer,
  query: EXPIRE_OFFER_MUTATION,
  method: GraphQlMethods.Post,
  transform: (response) => response.me.expireOffer,
  interceptors: [updateDealInterceptor],
});

export const deleteOffer = createNewEndpoint<
  DeleteOfferResponse,
  { id: string },
  { id: string; status: OfferStatus }
>({
  id: DealsActionType.DeleteOffer,
  query: DELETE_OFFER_MUTATION,
  method: GraphQlMethods.Post,
  transform: (_, variables) => ({
    id: variables.id,
    status: OfferStatus.DELETED,
  }),
  interceptors: [updateDealInterceptor],
});

export const sendDealToTelegram = createNewEndpoint<
  SendOfferResponse,
  { id: string }
>({
  id: DealsActionType.PostSendOfferToTelegram,
  query: SEND_TO_TELEGRAM_MUTATION,
  method: GraphQlMethods.Post,
});

export const reportOffer = createNewEndpoint<
  ReportOfferResponse,
  ReportOfferParam
>({
  id: DealsActionType.PostReportOffer,
  query: REPORT_OFFER_MUTATION,
  method: GraphQlMethods.Post,
});

export const updateOfferStatus = createNewEndpoint<
  UpdateOfferStatusResponse,
  { id: string; input: { status: OfferStatus } },
  UpdateOfferStatusResponse['backoffice']['updateOfferStatus']
>({
  id: DealsActionType.PostUpdateOfferStatus,
  query: UPDATE_OFFER_STATUS_MUTATION,
  method: GraphQlMethods.Post,
  transform: (response) => response.backoffice.updateOfferStatus,
  interceptors: [updateDealInterceptor],
});

export const updateOfferIsPicked = createNewEndpoint<
  OfferIsPickedResponse,
  UpdateOfferIsPickedParam,
  OfferIsPickedResponse['backoffice']['updateOfferIsPicked']
>({
  id: DealsActionType.PostUpdateOfferIsPicked,
  query: UPDATE_OFFER_IS_PICKED_MUTATION,
  method: GraphQlMethods.Post,
  transform: (response) => response.backoffice.updateOfferIsPicked,
  interceptors: [updateDealInterceptor],
});

export const updateOfferLockedComments = createNewEndpoint<
  OfferLockedCommentsResponse,
  UpdateOfferLockedComentsParam,
  OfferLockedCommentsResponse['backoffice']['updateOfferLockedComments']
>({
  id: DealsActionType.PostUpdateOfferLockedComments,
  query: UPDATE_OFFER_LOCKED_COMMENTS_MUTATION,
  method: GraphQlMethods.Post,
  transform: (response) => response.backoffice.updateOfferLockedComments,
  interceptors: [updateDealInterceptor],
});

export const updateOfferIsTracked = createNewEndpoint<
  OfferIsTrackedResponse,
  UpdateOfferIsTrackedParam | void,
  OfferIsTrackedResponse['backoffice']['updateOfferIsTracked']
>({
  id: DealsActionType.PostUpdateOfferIsTracked,
  query: UPDATE_OFFER_IS_TRACKED_MUTATION,
  method: GraphQlMethods.Post,
  transform: (response) => response.backoffice.updateOfferIsTracked,
  interceptors: [updateDealInterceptor],
});

export const updateOfferExpireLock = createNewEndpoint<
  UpdateOfferExpireLockResponse,
  UpdateOfferExpireLockParam | void,
  UpdateOfferExpireLockResponse['backoffice']['updateOfferExpireLock']
>({
  id: DealsActionType.PostUpdateOfferExpireLock,
  query: UPDATE_OFFER_EXPIRE_LOCK_MUTATION,
  method: GraphQlMethods.Post,
  transform: (response) => response.backoffice.updateOfferExpireLock,
  interceptors: [updateDealInterceptor],
});

export const getDuplicatedDeals = createNewEndpoint<
  DuplicatedDealsResponse,
  { input: DuplicatedDealsVariables } | void,
  DuplicatedDealsResponse['public']['duplicatedDeals']
>({
  id: DealsActionType.GetDuplicatedDeals,
  query: DUPLICATED_DEALS_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.duplicatedDeals,
});

export const getHottestDeals = createNewEndpoint<
  HottestOffersQueryResponse,
  { categorySlug?: string; storeId?: string },
  HottestOffersQueryResponse['public']['hottestOffers']
>({
  id: DealsActionType.GetHottestDeals,
  query: HOTTEST_OFFERS_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.hottestOffers,
});

export const getRecommendedHottestDeals = createNewEndpoint<
  RecommendationsHottestOffersQueryResponse,
  { offerId: string; limit: number },
  RecommendationsHottestOffersQueryResponse['me']['recommendationsFromOffer']
>({
  id: DealsActionType.GetRecommendationsHottestDeals,
  guards: [PreventUnauthenticatedGuard],
  query: getRecommendationsFromOfferQueryText(true),
  method: GraphQlMethods.Get,
  transform: (response) => response.me.recommendationsFromOffer,
});
