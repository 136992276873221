const ALERTS_SUGGESTIONS_MODAL = 'ALERTS_SUGGESTIONS_MODAL';

export function setAlertsSuggestionsModalLocalStorage() {
  const key = ALERTS_SUGGESTIONS_MODAL;

  if (!window) return;

  const value = window.localStorage.getItem(key);
  const newValue = value ? Number(value) + 1 : 1;

  window.localStorage.setItem(key, newValue.toString());
}

export function getAlertsSuggestionsModalLocalStorage() {
  if (typeof window !== 'undefined') {
    const value = window.localStorage.getItem(ALERTS_SUGGESTIONS_MODAL);
    return Number(value);
  }
  return undefined;
}
