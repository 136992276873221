export const DUPLICATED_DEALS_QUERY = `
  query DuplicatedDealsQuery($input: duplicatedOffersInput!) {
    public {
      duplicatedDeals: duplicatedOffers(input: $input) {
        id
        title
        kind
        status
      }
    }
  }
`;
