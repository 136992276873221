import env from '@/config/environment';
import React, { useCallback, useEffect, useState } from 'react';
import { getProbablyLoggedCookie } from '@/infra/auth/tokenManagement';
import {
  CLIENT_INFO_HEADER_NAME,
  getClientOperationMetadataInBase64,
  getDeviceFromUserAgent,
} from '@/infra/api/core/tracker';
import { getDealsAndCouponsAsPath } from '@/presentation/services/regex';
import { useSocialAuthToastError } from '@/presentation/hooks/useSocialAuthToastError';
import {
  GoogleLoginData,
  LoginComponentSourceName,
} from '../Authentication/types';
import { useHandleAuthGoogleOnTap } from '../Authentication/hooks/useHandleAuth';

type GoogleLoginBtnProps = {
  googleClientId?: string;
  path?: string;
  onFinished?: () => void;
};

export default function GoogleLoginBtn({
  googleClientId,
  path,
  onFinished = () => {},
}: GoogleLoginBtnProps) {
  const { handleAuth } = useHandleAuthGoogleOnTap({
    onFinished,
  });
  const { showSocialAuthToastError } = useSocialAuthToastError();

  const isLogged = getProbablyLoggedCookie();
  const [randomNumber] = useState(Math.floor(Math.random() * 101));

  let userAgent = '';

  if (typeof navigator !== 'undefined') {
    userAgent = navigator.userAgent;
  }

  const renderGoogleOneTapButton = useCallback(() => {
    async function onGoogleSignIn(response: GoogleLoginData) {
      try {
        const result = await fetch(
          `${env.PUBLIC_REST_API_URL}/auth/google-one-tap/callback`,
          {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              [CLIENT_INFO_HEADER_NAME]: getClientOperationMetadataInBase64(
                {
                  componentSourceName: LoginComponentSourceName.googleOneTap,
                  clientDevice: getDeviceFromUserAgent(userAgent),
                  clientUserAgent: userAgent,
                  path,
                  groupedPath: getDealsAndCouponsAsPath(path || ''),
                },
                true
              ),
            },
            body: JSON.stringify(response),
          }
        );
        const authResult = await result.json();
        handleAuth(authResult.data);
      } catch {
        showSocialAuthToastError();
      }
    }

    const { google } = window;
    if (google) {
      google.accounts.id.initialize({
        client_id: googleClientId || '',
        callback: onGoogleSignIn,
      });
      google.accounts.id.renderButton(
        document.getElementById(`google-login-btn-${randomNumber}`),
        {
          type: 'standard',
          theme: 'outline',
          size: 'large',
          text: 'signin_with',
          shape: 'circle',
          width: document.getElementById(`google-login-btn-${randomNumber}`)
            ?.offsetWidth,
          height: document.getElementById(`google-login-btn-${randomNumber}`)
            ?.offsetHeight,
        }
      );

      if (!isLogged) {
        google.accounts.id.prompt();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleClientId, randomNumber]);

  useEffect(() => {
    renderGoogleOneTapButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <span id={`google-login-btn-${randomNumber}`} style={{ minWidth: 266 }} />
  );
}
