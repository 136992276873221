import { DelayedDealListCardSkeleton } from '../FeedCard/style';
import { FeedCardList, ListItem } from './style';

const generateFeedListCard = (cardsLength: number) => (
  <FeedCardList>
    {Array(cardsLength)
      .fill('')
      .map((_, cardIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <ListItem key={`offer-skeleton-${cardIndex}`}>
          <DelayedDealListCardSkeleton
            animate
            cardIndex={cardIndex}
            cardsLength={cardsLength}
          />
        </ListItem>
      ))}
  </FeedCardList>
);

export default generateFeedListCard;
