import { Bell, FlagSolid, Flag } from '@pelando/fontawesome/icons';
import { Image } from '@pelando/components';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { AlertSuggestion, AlertType } from '@/domain/entities/Alert';
import { useCallback, useState } from 'react';
import {
  IconTitle,
  Subtitle,
  ModalButton,
  Container,
  Title,
  AlertContent,
  IconAlert,
  AlertText,
} from './style';

export enum AlertModalType {
  CREATE_ALERT = 'CREATE_ALERT',
  SUCCESS_ALERT = 'SUCCESS_ALERT',
}

const AlertSuggestionsModal = ({
  recentSearch,
  onCreateAlert,
}: {
  recentSearch: string;
  onCreateAlert: (
    suggestion: AlertSuggestion,
    alertModalType: AlertModalType
  ) => void;
}) => {
  const { t } = useTranslation('search');
  const [alertModalType, setAlertModaltype] = useState<AlertModalType>(
    AlertModalType.CREATE_ALERT
  );

  const suggestion = {
    term: recentSearch,
    type: AlertType.Smart,
    manyNotifications: false,
  };

  const handleButtonClick = useCallback(() => {
    if (alertModalType === AlertModalType.CREATE_ALERT) {
      setAlertModaltype(AlertModalType.SUCCESS_ALERT);
    }
    onCreateAlert(suggestion, alertModalType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertModalType]);

  const title =
    alertModalType === AlertModalType.CREATE_ALERT
      ? t('alert-suggestion-modal-title-create', recentSearch)
      : t('alert-suggestion-modal-title-success');

  const subTitle =
    alertModalType === AlertModalType.CREATE_ALERT
      ? t('alert-suggestion-modal-subtitle-create')
      : t('alert-suggestion-modal-subtitle-success');

  const buttonText =
    alertModalType === AlertModalType.CREATE_ALERT
      ? t('alert-suggestion-modal-button-create')
      : t('alert-suggestion-modal-button-success');

  const icon =
    alertModalType === AlertModalType.CREATE_ALERT ? Flag : FlagSolid;

  return (
    <Container>
      {alertModalType === AlertModalType.CREATE_ALERT ? (
        <IconTitle icon={Bell} />
      ) : (
        <Image
          width={92}
          height={92}
          alt="success-icon"
          src="/assets/search/alert-incentive-modal-68x68.gif"
          fetchPriority="high"
        />
      )}
      <Title>{title}</Title>
      <Subtitle>{subTitle}</Subtitle>
      <AlertContent>
        <IconAlert
          icon={icon}
          $success={alertModalType === AlertModalType.SUCCESS_ALERT}
        />
        <AlertText>{recentSearch}</AlertText>
      </AlertContent>
      <ModalButton onClick={handleButtonClick}>{buttonText}</ModalButton>
    </Container>
  );
};

export default AlertSuggestionsModal;
