import {
  type DealStatus,
  type DealDispatcher,
  type DealAction,
  type DealVoteAction,
  type SaveDealAction,
  type DealDispatcherPayload,
} from '@pelando/components';
import { useEffect, useRef } from 'react';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { useRouter } from 'next/router';
import { Langs } from '@/locales';
import { FeedAlertItem } from '@/domain/entities/Alert';
import {
  FeedDeal,
  CommentedOffer,
  FeaturedOffer,
} from '@/domain/entities/Offer';
import { RemoveVoteVariables } from '@/infra/api/votes/types/RemoveVote';
import { VoteVariables } from '@/infra/api/votes/types/Vote';
import getDealValue from '@/presentation/services/deals/getDealValue';
import getFormattedTimestamp from '@/presentation/services/deals/getFormattedTimestamp';
import getStampType from '@/presentation/services/deals/getStampType';
import {
  getDealUrl,
  getCouponsUrl,
  getCommentsSectionDealUrl,
} from '@/presentation/services/urls';
import { LoggedUser } from '@/domain/entities/User';
import getTimestampTitleByCategory from '../FeedContent/services/getTimestampTitleByCategory';
import useOpenDealActionHandler from './hooks/useClickoutActionHandler';
import useMutationActionHandler from './hooks/useMutationActionHandler';
import useHeaderContentHandler from './hooks/useHeaderContentHandler';
import useCardActionHandler from './hooks/useCardActionHandler';
import { Card, Title } from './style';

export type DealDetails = Pick<
  FeedDeal & CommentedOffer & FeedAlertItem & FeaturedOffer,
  | 'id'
  | 'commentCount'
  | 'title'
  | 'status'
  | 'timestamps'
  | 'store'
  | 'temperature'
  | 'kind'
  | 'discountFixed'
  | 'discountPercentage'
  | 'freeShipping'
  | 'price'
  | 'image'
  | 'voteInfo'
  | 'lastComment'
  | 'keyword'
  | 'user'
  | 'recommendationId'
  | 'isTracked'
  | 'sourceUrl'
  | 'isSaved'
  | 'cashback'
>;

export type FeedCardProps = {
  deal: DealDetails;
  categorySlug?: string;
  currentUser?: LoggedUser;
  onVote: (variables: VoteVariables) => void;
  onRemoveVote: (variables: RemoveVoteVariables) => void;
  onMount?: (target: Element) => void;
  onUnmount?: (target: Element) => void;
  position: number;
  titleAsHeading?: boolean;
  onClick?: () => void;
};

export default function FeedCard({
  deal,
  titleAsHeading,
  categorySlug,
  position,
  onVote,
  onRemoveVote,
  onMount,
  onUnmount,
  onClick,
}: FeedCardProps) {
  const { t } = useTranslation('common');
  const { locale } = useRouter();
  const cardRef = useRef<HTMLDivElement>(null);
  const headerContent = useHeaderContentHandler({
    deal,
    categorySlug,
    position,
  });
  const { store } = deal;
  const storeLabel = t('store-label');

  const dealUrl = getDealUrl({
    id: deal.id,
    title: deal.title,
    recommendationId: deal.recommendationId,
  });

  const commentUrl = getCommentsSectionDealUrl(dealUrl);

  const clickoutHandler = useOpenDealActionHandler({
    storeName: store?.name,
    dealUrl,
    cardPosition: position,
    dealId: deal.id,
  });

  const mutationHandler = useMutationActionHandler({
    onRemoveVote,
    onVote,

    recommendationId: deal.recommendationId,
  });

  const actionHandler = useCardActionHandler({
    deal: {
      id: deal.id,
      title: deal.title,
      kind: deal.kind,
      status: deal.status,
      price: deal.price,
    },
  });

  const handleDispatch: DealDispatcher = ({ type, payload }) => {
    clickoutHandler(type as DealAction);
    mutationHandler(
      type as DealVoteAction | SaveDealAction,
      payload as unknown as DealDispatcherPayload<DealVoteAction>
    );
    actionHandler(type as DealAction);
  };

  useEffect(() => {
    const element = cardRef?.current;

    if (onMount && element) {
      onMount(element);
    }

    return () => {
      if (onUnmount && element) {
        onUnmount(element);
      }
    };
  }, [onMount, onUnmount]);

  return (
    <Card
      onClick={onClick}
      innerRef={cardRef}
      id={deal.id}
      dealUrl={dealUrl}
      commentsDealUrl={commentUrl}
      commentCount={deal.commentCount}
      title={
        (titleAsHeading ? <Title>{deal.title}</Title> : deal.title) as string
      }
      dispatch={handleDispatch}
      status={deal.status as unknown as DealStatus}
      timestamp={getFormattedTimestamp({
        timestamps: deal.timestamps,
        language: locale || '',
        categorySlug,
      })}
      timestampTitle={getTimestampTitleByCategory(
        categorySlug || '',
        locale as Langs
      )}
      stampType={getStampType(deal)}
      storeSlug={store?.slug || ''}
      temperature={Math.round(deal.temperature)}
      image={deal.image?.url}
      storeImage={store?.image?.url}
      storeName={store?.name}
      storeLink={getCouponsUrl({ storeSlug: store?.slug || '', locale })}
      voteInfo={deal.voteInfo}
      value={getDealValue(deal)}
      headerContent={headerContent}
      storeText={storeLabel}
      locale={locale || ''}
      cashbackPercentage={deal.cashback?.percentage}
    />
  );
}
