export const PRODUCT_PRICE_HISTORY = `
  query ProductPriceHistory($id: ID!) {
    public {
      productPriceHistory(id: $id) {
        values {
          price
          date
        }
      }
    }
  }
`;
