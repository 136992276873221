import { Deal } from '../../../domain/entities/Offer';
import {
  SpecialFeedTabSlugPtBR,
  SpecialFeedTabSlugEnUS,
} from '../feedTabsCategories';
import { getTimeAgoString } from '../time/getTimeAgo';

type GetFormattedTimestampParams = {
  timestamps: Pick<
    Deal['timestamps'],
    'createdAt' | 'firstApprovedAt' | 'lastCommentedAt' | 'pickedAt'
  >;
  language: string;
  categorySlug?: string;
};

export default function getFormattedTimestamp({
  timestamps,
  language,
  categorySlug,
}: GetFormattedTimestampParams) {
  const isCommented = [
    SpecialFeedTabSlugPtBR.COMMENTED,
    SpecialFeedTabSlugEnUS.COMMENTED
  ].includes(categorySlug as SpecialFeedTabSlugPtBR)

  const preferredTimestamp =
    (isCommented && timestamps.lastCommentedAt) ||
    timestamps.firstApprovedAt ||
    timestamps.createdAt;

  return getTimeAgoString(new Date(preferredTimestamp), language);
}
