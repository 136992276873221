'use client';

import Script from 'next/script';
import { firstValueFrom, ReplaySubject } from 'rxjs';
import { isAdPlacementDisabled } from '../../services/featureFlags';

export type GooglePubTag = typeof googletag;

const GooglePubAdsSubject$ = new ReplaySubject<GooglePubTag>(1);

export const getGooglePubTag = () => firstValueFrom(GooglePubAdsSubject$);

export function GooglePubAdsScript() {
  if (isAdPlacementDisabled()) return null;

  return (
    <Script
      id="googlepublishertag"
      key="googlepublishertag"
      src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
      strategy="afterInteractive"
      onLoad={() => {
        if (window.PubadsActive) return;
        window.PubadsActive = true;
        window.googletag = window.googletag || { cmd: [] };
        window.googletag.cmd.push(() => {
          window.googletag.pubads().enableSingleRequest();
          window.googletag.enableServices();
          GooglePubAdsSubject$.next(window.googletag);
        });
      }}
    />
  );
}
