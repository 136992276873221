export const GET_ASSOCIATED_OFFERS = `
  query AssociatedOffers($limit: Int) {
    public {
      associatedOffers(limit: $limit) {
        id
        title
        kind
        status
        productId
        temperature
        price
        discountPercentage
        discountFixed
        freeShipping
        commentCount
        timestamps {
          approvedAt
        }
        image {
          id
          preview: url(width: 36)
          url(width: 108)
        }
      }
    }
  }
`;
