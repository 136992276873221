import {
  DEAL_TIMESTAMPS_FRAGMENT,
  DEAL_TIP_FRAGMENT,
} from '../../fragments/deal';

export const DEAL_TO_EDIT_QUERY = `
  query DealToEditQuery($id: ID!) {
    public {
      deal: offer(id: $id) {
        id
        body: content
        noIndex
        commentCount
        couponCode
        discountFixed
        discountPercentage
        freeShipping
        image {
          id
          url
        }
        isLocal
        isFirstOffer
        isPicked
        isTracked
        expireLock
        lockedComments
        kind
        price
        status
        temperature
        title
        sourceUrl
        isTracked
        hideInFeed
        genderPreference


        tip {
          ...DealTipFragment
        }

        deletionReasons {
          id
          title
          link
          bannerTitle
          description
        }

        timestamps {
          ...DealTimestampsFragment
        }

        category {
          id
          slug
          title
        }

        user {
          id
          image {
            id
            url(width: 64, height: 64)
          }
          nickname
        }

        store {
          id
          name
          slug
          url
          image {
            id
            url
          }
        }
      }
    }
  }

  ${DEAL_TIMESTAMPS_FRAGMENT}
  ${DEAL_TIP_FRAGMENT}
`;
