import { useTranslation } from '@/presentation/hooks/useTranslation';
import { Apple, Envelope, Facebook } from '@pelando/fontawesome/icons';
import ExternalAgreementLink from '@/presentation/components/Authentication/components/ExternalAgreementLink';
import { AgreementType } from '@/domain/entities/Agreement';
import { useAuthModal } from '@/presentation/components/Authentication/hooks/useAuthModal';
import {
  AuthKind,
  AuthProvider,
  LoginComponentSourceName,
} from '@/presentation/components/Authentication/types';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import {
  LoginSideBarButton,
  LoginSideBarButtonsContainer,
  LoginSideBarContainer,
  LoginSideBarIcon,
  LoginSideBarTitle,
  TermsOfUse,
} from './style';
import GoogleLoginBtn from '../GoogleLoginButton';

const AuthModal = dynamic(
  () =>
    import('@/presentation/components/Authentication').then(
      (mod) => mod.default
    ),
  { ssr: false }
);

export type LoginSideBarProps = {
  componentSourceName: LoginComponentSourceName;
  googleClientId?: string;
};

function LoginSideBar({
  componentSourceName,
  googleClientId,
}: LoginSideBarProps) {
  const { t } = useTranslation('login');
  const { asPath } = useRouter();
  const { showModal, closeModal } = useAuthModal();

  const mapAgreementTypeToText = (agreementType: AgreementType) =>
    ((
      {
        [AgreementType.PRIVACY_POLICY]: t('login-privacy-policy'),
        [AgreementType.TERMS_OF_SERVICE]: t('login-terms-of-service'),
      } as Record<AgreementType, string>
    )[agreementType]);

  const handleSocialShowAuthModal = (provider: AuthProvider) =>
    showModal(
      <AuthModal
        kind={AuthKind.LOGIN}
        onCloseModal={closeModal}
        defaultProvider={provider}
        componentSourceName={componentSourceName}
      />
    );
  return (
    <LoginSideBarContainer>
      <LoginSideBarTitle> {t('login-sidebar-title')}</LoginSideBarTitle>
      <LoginSideBarButtonsContainer>
        <LoginSideBarButton
          light
          onClick={() => {
            showModal(
              <AuthModal
                kind={AuthKind.LOGIN}
                onCloseModal={closeModal}
                componentSourceName={componentSourceName}
              />
            );
          }}
        >
          <LoginSideBarIcon icon={Envelope} />
        </LoginSideBarButton>
        <LoginSideBarButton
          light
          onClick={() => {
            handleSocialShowAuthModal(AuthProvider.FACEBOOK);
          }}
        >
          <LoginSideBarIcon icon={Facebook} />
        </LoginSideBarButton>
        <LoginSideBarButton
          light
          onClick={() => {
            handleSocialShowAuthModal(AuthProvider.APPLE);
          }}
        >
          <LoginSideBarIcon icon={Apple} />
        </LoginSideBarButton>
      </LoginSideBarButtonsContainer>
      <GoogleLoginBtn googleClientId={googleClientId} path={asPath} />
      <TermsOfUse>
        {t('login-terms-of-use-text')}
        <div>
          <ExternalAgreementLink
            agreementType={AgreementType.TERMS_OF_SERVICE}
            getAgreementLinkText={mapAgreementTypeToText}
          />
          {t('login-terms-of-use-joining')}
          <ExternalAgreementLink
            agreementType={AgreementType.PRIVACY_POLICY}
            getAgreementLinkText={mapAgreementTypeToText}
          />
        </div>
      </TermsOfUse>
    </LoginSideBarContainer>
  );
}

export default LoginSideBar;
