export const COMMENT_DETAILS = `
  id
  liked
  content
  likeCount
  status
  isEdited
  parentId

  timestamps {
    createdAt
    approvedAt
    updatedAt
    firstApprovedAt
  }

  author {
    id
    image {
      id
      url(width: 64, height: 64)
    }
    nickname
    isVerified
  }

  totalReplies

  reactions {
    like
    funny
    helpful
  }

  offer {
    id
    title
  }

  totalReactionsCount
`;
