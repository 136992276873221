export const RELATED_PRODUCTS_QUERY = `
query RelatedProductsQuery($id: ID!) {
  public {
    relatedProducts(id: $id) {
      nominalModel
      products {
        id
        title
        storageCapacity
        availableColors: availableColorsV2 {
          color
          image {
            id
            url(width: 408)
          }
        }
      }
    }
  }
}
`;
