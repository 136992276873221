export const PRODUCTS_VARIANTS_QUERY = `
query ProductsVariantsQuery($id: ID!) {
  public {
    productVariants(id: $id) {
      variants {
        type
        values {
          productId
          productTitle
          name
          currentVariant
          genericVariant
          image {
            id
            url
          }
        }
      }
    }
  }
}
`;
