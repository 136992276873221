import { useCallback } from 'react';
import { useModal } from '@pelando/components';
import AlertSuggestionsModal, {
  AlertModalType,
} from '@/presentation/components/AlertSuggestionsModal';
import {
  getAlertsSuggestionsModalLocalStorage,
  setAlertsSuggestionsModalLocalStorage,
} from '@/infra/storage/alertSuggestionStorage';
import { getListFromLocalStorage } from '@/presentation/components/Header/hooks/Search/useRecentSearches';
import { useEnhancedAlerts } from '@/presentation/hooks/useEnhancedAlerts';
import { AlertSuggestion, CreateAlertSource } from '@/domain/entities/Alert';
import { useRouter } from 'next/router';
import { getWishlistUrl } from '@/presentation/services/urls';
import { isIncentiveAlertCreateEnabled } from '@/presentation/services/featureFlags';
import {
  AlertEvents,
  AlertsComponentNames,
  EventCategory,
} from '@/domain/events/analytics';
import { emitTrackingEvent } from '@/infra/analytics/emitTrackingEvent';
import { getFromSearchPage } from '@/presentation/services/search';
import { getProbablyLoggedCookie } from '@/infra/auth/tokenManagement';

export function useAlertSuggestionsModal(previousPage?: string | null) {
  const { showModal, closeModal } = useModal();
  const { push, locale, pathname } = useRouter();
  const { alerts, createHandler } = useEnhancedAlerts();
  const isLogged = getProbablyLoggedCookie();
  const isIncentiveAlertCreate = isIncentiveAlertCreateEnabled();
  const isSearchPagePath = pathname.includes('busca');
  const isSearchPreviuosPage = getFromSearchPage(
    previousPage || '',
    locale || ''
  );
  const shouldShowTheModal =
    alerts.length === 0 &&
    isLogged &&
    (isSearchPreviuosPage || isSearchPagePath);

  const onCreateAlertModal = useCallback(
    (suggestion: AlertSuggestion, alertModalType: AlertModalType) => {
      const alertsUrl = getWishlistUrl(locale);

      if (alertModalType === AlertModalType.CREATE_ALERT) {
        createHandler({
          suggestion,
          source: CreateAlertSource.MANUAL,
          componentName: AlertsComponentNames.AlertSuggestionModalSearchPage,
          haveToshowToast: false,
        });
      } else {
        push(alertsUrl);
        closeModal();
      }
    },
    [closeModal, createHandler, locale, push]
  );

  const triggerAlertModal = useCallback(() => {
    if (!isIncentiveAlertCreate) return;

    const alertSuggestionModalLocalStorageValue =
      getAlertsSuggestionsModalLocalStorage();

    if (
      alertSuggestionModalLocalStorageValue &&
      alertSuggestionModalLocalStorageValue >= 2
    ) {
      return;
    }

    if (shouldShowTheModal) {
      setAlertsSuggestionsModalLocalStorage();
      showModal(
        <AlertSuggestionsModal
          recentSearch={getListFromLocalStorage()?.[0]}
          onCreateAlert={onCreateAlertModal}
        />
      );

      emitTrackingEvent({
        category: EventCategory.Alert,
        name: AlertEvents.ModalSuggestionAlert,
        extra: undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { triggerAlertModal };
}
