import { Langs } from '@/locales';
import {
  SpecialFeedTabSlugPtBR,
  SpecialFeedTabSlugEnUS,
} from '@/presentation/services/feedTabsCategories';

const timestampTitleByCategoryBR = {
  [SpecialFeedTabSlugPtBR.FEATURED]: 'Hora do destaque',
  [SpecialFeedTabSlugPtBR.HOTTEST]: 'Hora das mais quentes',
  [SpecialFeedTabSlugPtBR.COMMENTED]: 'Hora do último comentário',
};

const timestampTitleByCategoryUS = {
  [SpecialFeedTabSlugEnUS.FEATURED]: 'Featured time',
  [SpecialFeedTabSlugEnUS.HOTTEST]: 'Hottest time',
  [SpecialFeedTabSlugEnUS.COMMENTED]: 'Last comment time',
};

export default function getTimestampTitleByCategory(
  slug: string,
  locale: Langs
) {
  const timeStampTitleMap =
    locale === 'pt-BR'
      ? timestampTitleByCategoryBR[
          slug as keyof typeof timestampTitleByCategoryBR
        ] || 'Hora da publicação'
      : timestampTitleByCategoryUS[
          slug as keyof typeof timestampTitleByCategoryUS
        ] || 'Publish time';
  return timeStampTitleMap;
}
