import { type Comment } from '@/domain/entities/Comment';
import {
  Product,
  TopProductsByCategory,
  TopProductsByModel,
} from '@/domain/entities/Product';
import { GET_ASSOCIATED_OFFERS } from '@/infra/api/products/queries/associatedOffers';
import {
  GetAssociatedDealsCache,
  GetAssociatedDealsResponse,
  GetAssociatedDealsTransformed,
  GetAssociatedDealsVariables,
} from '@/infra/api/products/types/GetAssociatedDeals';
import { PRODUCTS_SUGGESTIONS_QUERY } from '@/infra/api/products/queries/productSuggestions';
import {
  GetProductsSuggestionsCache,
  GetProductsSuggestionsResponse,
  GetProductsSuggestionsVariables,
} from '@/infra/api/products/types/GetProductSuggestions';
import { createNewEndpoint } from '../core/endpoint';
import { GraphQlMethods } from '../core/fetch';
import { ProductsActionType } from './actionTypes';
import { PRODUCT_QUERY } from './queries/product';
import { PRODUCT_COMMENTS_QUERY } from './queries/productComments';
import { GetProductResponse, GetProductVariables } from './types/GetProduct';
import {
  GetProductCommentsResponse,
  GetProductCommentsVariables,
} from './types/GetProductComments';
import { GET_PRODUCT_DEALS_QUERY } from './queries/productDeals';
import { RELATED_PRODUCTS_QUERY } from './queries/relatedProducts';
import {
  GetProductDealsCache,
  GetProductDealsResponse,
  GetProductDealsVariables,
  ProductDeals,
} from './types/GetProductDeals';
import {
  RelatedProductsCache,
  RelatedProductsResponse,
  RelatedProductsVariables,
} from './types/GetRelatedProducts';
import {
  GetProductPriceHistoryCache,
  GetProductPriceHistoryResponse,
  GetProductPriceHistoryVariables,
} from './types/GetProductPriceHistory';
import { PRODUCT_PRICE_HISTORY } from './queries/productPriceHistory';
import {
  GetTopProductsByModelResponse,
  GetTopProductsByModelVariables,
} from './types/GetTopProductsByModel';
import { TOP_PRODUCTS_BY_MODEL_QUERY } from './queries/topProductsByModel';
import {
  GetTopProductsByCategoryResponse,
  GetTopProductsByCategoryVariables,
} from './types/GetTopProductsByCategory';
import { TOP_PRODUCTS_BY_CATEGORY_QUERY } from './queries/topProductsByCategory';
import {
  GetProductVariantsCache,
  GetProductVariantsResponse,
  GetProductVariantsVariables,
} from './types/GetProductsVariants';
import { PRODUCTS_VARIANTS_QUERY } from './queries/productVariants';

export const getProduct = createNewEndpoint<
  GetProductResponse,
  GetProductVariables,
  Product,
  Product & { loading?: boolean }
>({
  id: ProductsActionType.GetProduct,
  query: PRODUCT_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.product,
  cacheTransform: ({ data, loading }, current) => ({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ...(data || current || ({} as unknown as typeof current))!,
    loading,
  }),
});

export const getRelatedProducts = createNewEndpoint<
  RelatedProductsResponse,
  RelatedProductsVariables,
  RelatedProductsResponse['public']['relatedProducts'],
  RelatedProductsCache
>({
  id: ProductsActionType.GetRelatedProducts,
  query: RELATED_PRODUCTS_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.relatedProducts,
  cacheTransform: ({ data, loading }, current) => {
    const incomingProducts = data?.products || [];
    const nominalModel = data?.nominalModel || '';
    const currentProducts = current?.productsByNominalModel || {};

    if (!data || !nominalModel)
      return {
        ...current,
        productsByNominalModel: { ...currentProducts },
        loading,
      };

    return {
      ...current,
      productsByNominalModel: {
        ...currentProducts,
        [nominalModel]: incomingProducts,
      },
      loading,
    };
  },
});

export const getProductsDeals = createNewEndpoint<
  GetProductDealsResponse,
  GetProductDealsVariables,
  ProductDeals,
  GetProductDealsCache
>({
  id: ProductsActionType.GetProductDeals,
  query: GET_PRODUCT_DEALS_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.productOffers,
  cacheTransform: ({ loading, data }, current) => {
    const isPaginating = !!current?.edges?.length;
    const paginationLoading = loading && isPaginating;
    const currentEdges = current?.edges || [];
    const incomingEdges = data?.edges || [];

    return {
      loading: loading && !isPaginating,
      paginationLoading,
      edges: loading ? currentEdges : incomingEdges,
      pageInfo: loading ? current?.pageInfo : data?.pageInfo,
    };
  },
});

export const getProductComments = createNewEndpoint<
  GetProductCommentsResponse,
  GetProductCommentsVariables,
  Comment[],
  Comment[]
>({
  id: ProductsActionType.GetProduct,
  query: PRODUCT_COMMENTS_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.topProductComments,
  cacheTransform: (response) => response.data || [],
});

export const getProductPriceHistory = createNewEndpoint<
  GetProductPriceHistoryResponse,
  GetProductPriceHistoryVariables,
  GetProductPriceHistoryResponse['public']['productPriceHistory'],
  GetProductPriceHistoryCache
>({
  id: ProductsActionType.GetProductPriceHistory,
  query: PRODUCT_PRICE_HISTORY,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.productPriceHistory,
  cacheTransform: ({ data, variables, loading }, current) => {
    const nextData =
      variables?.id && data ? { [variables.id]: data.values } : {};

    return {
      valuesById: {
        ...current?.valuesById,
        ...nextData,
      },
      loading,
    };
  },
});

export const getAssociatedDeals = createNewEndpoint<
  GetAssociatedDealsResponse,
  GetAssociatedDealsVariables,
  GetAssociatedDealsTransformed,
  GetAssociatedDealsCache
>({
  id: ProductsActionType.GetAssociatedDeals,
  query: GET_ASSOCIATED_OFFERS,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.associatedOffers,
  cacheTransform: ({ loading, data }) => ({
    loading,
    deals: data,
  }),
});

export const getTopProductsByCategory = createNewEndpoint<
  GetTopProductsByCategoryResponse,
  GetTopProductsByCategoryVariables,
  TopProductsByCategory[]
>({
  id: ProductsActionType.GetTopProductsByCategory,
  query: TOP_PRODUCTS_BY_CATEGORY_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.topProductsByCategory.edges,
});

export const getTopProductsByModel = createNewEndpoint<
  GetTopProductsByModelResponse,
  GetTopProductsByModelVariables,
  TopProductsByModel[]
>({
  id: ProductsActionType.GetTopProductsByModel,
  query: TOP_PRODUCTS_BY_MODEL_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.topProductsByModel.edges,
});

export const getProductsSuggestions = createNewEndpoint<
  GetProductsSuggestionsResponse,
  GetProductsSuggestionsVariables,
  GetProductsSuggestionsResponse['public']['productSuggestions'],
  GetProductsSuggestionsCache
>({
  id: ProductsActionType.GetProductsSuggestions,
  query: PRODUCTS_SUGGESTIONS_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.productSuggestions,
  cacheTransform: ({ loading, data }) => ({
    loading,
    products: data || [],
  }),
});

export const getProductsVariants = createNewEndpoint<
  GetProductVariantsResponse,
  GetProductVariantsVariables,
  GetProductVariantsResponse['public']['productVariants'],
  GetProductVariantsCache
>({
  id: ProductsActionType.GetProductVariants,
  query: PRODUCTS_VARIANTS_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.productVariants,
  cacheTransform: ({ loading, data, variables }, current) => {
    const incomingVariants = data?.variants;
    const id = variables?.id;

    if (!id || !incomingVariants) {
      return {
        byId: current?.byId || {},
        loading,
      };
    }

    return {
      byId: { ...(current?.byId || {}), [id]: incomingVariants },
      loading,
    };
  },
});
