import {
  CASHBACK_FRAGMENT_OFFER,
  CASHBACK_FRAGMENT_PRODUCT_OFFER,
} from '@/infra/api/fragments/cashback';

export const PRODUCT_IMAGE_FRAGMENT = `
  fragment ProductImageFragment on Media {
    id
    url(width: 408)
  }
`;

export const PRODUCT_EXTERNAL_OFFER_FRAGMENT = `
  fragment ProductExternalOfferFragment on ExternalOfferV1 {
    id
    title
    image {
      id
      url(width: 100)
    }
    priceString: price
    store {
      id
      slug
      name
      url
      image {
        id
        url(width: 32)
      }
    }
    url
    ammountOfParcels
    parcelValue
    ean
   ...CashbackFragmentProductOffer
  }

  ${CASHBACK_FRAGMENT_PRODUCT_OFFER}
`;

export const PRODUCT_COMMUNITY_DEAL_FRAGMENT = `
  fragment ProductCommunityDealFragment on Offer {
    id
    title
    price
    sourceUrl
    couponCode
    store {
      id
      slug
      name
      url
      image {
        id
        url(width: 32)
      }
    }
    image {
      id
      url(width: 100)
    }  
    ...CashbackFragmentOffer
  }
    ${CASHBACK_FRAGMENT_OFFER}
`;
