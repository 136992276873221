import { useEffect, useRef, useState } from 'react';

export const LOCALSTORAGE_KEY = 'recent_searches';
export const RECENTS_LIMIT = 5;

const checkIfStringsAreEqual = (valueA: string, valueB: string) => {
  const result = Intl.Collator('pt-BR', {
    ignorePunctuation: true,
    sensitivity: 'base',
  }).compare(valueA, valueB);

  return result === 0;
};

export const getListFromLocalStorage = (): string[] => {
  const data = window.localStorage.getItem(LOCALSTORAGE_KEY);
  try {
    return data ? (JSON.parse(data) as string[]) : [];
  } catch {
    return [];
  }
};

const useRecentSearches = () => {
  const [recentsList, setRecentsList] = useState<string[]>([]);
  const listRef = useRef<string[]>([]);

  const updateAndSaveList = (newList: string[]) => {
    listRef.current = newList;
    window.localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(newList));

    setRecentsList(newList.slice(0, RECENTS_LIMIT));
  };

  const manageRecentsListSize = (oldList: string[], newItem: string) => {
    let newList = [...oldList];
    const valueIndex = oldList.findIndex((listItem) =>
      checkIfStringsAreEqual(listItem, newItem)
    );

    if (valueIndex > -1) {
      newList.splice(valueIndex, 1);
    }

    newList.unshift(newItem);

    if (newList.length >= RECENTS_LIMIT) {
      newList = newList.slice(0, RECENTS_LIMIT);
    }

    return newList;
  };

  const saveRecentItem = (value: string) => {
    const newList = manageRecentsListSize(listRef.current, value);
    updateAndSaveList(newList);
  };

  const removeRecentItem = (value: string) => {
    const newList = listRef.current.filter(
      (item) => !checkIfStringsAreEqual(item, value)
    );
    updateAndSaveList(newList);
  };

  useEffect(() => {
    const listOnStore = getListFromLocalStorage();
    listRef.current = listOnStore;
    setRecentsList(listOnStore.slice(0, RECENTS_LIMIT));
  }, []);

  return { recentsList, saveRecentItem, removeRecentItem };
};

export default useRecentSearches;
