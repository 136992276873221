import { MappedSize } from './types';

export enum TargetingKey {
  URL_DATA = 'url_data',
  VIEWPORT_WIDTH = 'viewport_width',
  VIEWPORT_HEIGHT = 'viewport_height',
  TAG_OF_PAGE = 'tag_of_page',
  RefreshCount = 'refresh_count',
}

export const enum SlotCode {
  FEED_HORIZONTAL01 = '/21664355600/site/feed_horizontal01',
  FEED_HORIZONTAL02 = '/21664355600/site/feed_horizontal02',
  FEED_HORIZONTAL03 = '/21664355600/site/feed_horizontal03',
  FEED_HORIZONTAL04 = '/21664355600/site/feed_horizontal04',
  FEED_HORIZONTAL05 = '/21664355600/site/feed_horizontal05',
  FEED_SPONSORSHIP_01 = '/21664355600/site/feed_sponsorship_01',
  FEED_MEDIUM_RECTANGLE = '/21664355600/site/feed_medium_rectangle',
  DEAL_DETAIL_MEDIUM_RECTANGLE = '/21664355600/site/deal_detail_medium_rectangle',

  GOOGLE_PUBLISHER_TAG_TEST = '/6355419/Travel/Europe/France/Paris',

  SEARCH_RESULTS_MEDIUM_RECTANGLE = '21664355600/site/search_results_medium_rectangle',
}

const MEDIUM_RECTANGLE_SIZE: MappedSize = {
  viewport: [0, 0],
  adSizes: [[300, 250]],
};

const FEED_HORIZONTAL_SIZES: MappedSize[] = [
  { viewport: [1216, 0], adSizes: [[970, 90]] },
  { viewport: [736, 0], adSizes: [[728, 90]] },
  MEDIUM_RECTANGLE_SIZE,
];

const FEED_SPONSORSHIP_SIZES: MappedSize[] = [
  { viewport: [0, 0], adSizes: [[288, 96]] },
  { viewport: [360, 0], adSizes: [[328, 109]] },
  { viewport: [412, 0], adSizes: [[380, 127]] },
  { viewport: [736, 0], adSizes: [[736, 92]] },
  { viewport: [1366, 0], adSizes: [[1302, 163]] },
];

const FEED_MEDIUM_RECTANGLE_SIZES: MappedSize[] = [
  {
    viewport: [976, 0],
    adSizes: [
      [300, 600],
      [160, 600],
      [300, 250],
    ],
  },
];

const SEARCH_RESULTS_MEDIUM_RECTANGLE_SIZES: MappedSize[] = [
  {
    viewport: [976, 0],
    adSizes: [
      [300, 600],
      [300, 250],
    ],
  },
];

export const SlotSizes: Record<SlotCode, MappedSize[]> = {
  [SlotCode.FEED_HORIZONTAL01]: FEED_HORIZONTAL_SIZES,
  [SlotCode.FEED_HORIZONTAL02]: FEED_HORIZONTAL_SIZES,
  [SlotCode.FEED_HORIZONTAL03]: FEED_HORIZONTAL_SIZES,
  [SlotCode.FEED_HORIZONTAL04]: FEED_HORIZONTAL_SIZES,
  [SlotCode.FEED_HORIZONTAL05]: FEED_HORIZONTAL_SIZES,
  [SlotCode.FEED_SPONSORSHIP_01]: FEED_SPONSORSHIP_SIZES,
  [SlotCode.FEED_MEDIUM_RECTANGLE]: FEED_MEDIUM_RECTANGLE_SIZES,
  [SlotCode.DEAL_DETAIL_MEDIUM_RECTANGLE]: [MEDIUM_RECTANGLE_SIZE],

  [SlotCode.GOOGLE_PUBLISHER_TAG_TEST]: FEED_SPONSORSHIP_SIZES,

  [SlotCode.SEARCH_RESULTS_MEDIUM_RECTANGLE]:
    SEARCH_RESULTS_MEDIUM_RECTANGLE_SIZES,
};

export const REFRESH_DEFAULT_INTERVAL = 30000;
