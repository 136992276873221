import { DealAction, DealCardAction } from '@pelando/components';

import { useRouter } from 'next/router';
import {
  EventCategory,
  GeneralDealEvents,
} from '../../../../../../domain/events/analytics';
import { emitTrackingEvent } from '../../../../../../infra/analytics/emitTrackingEvent';
import { emitGoToDealDetailsEvent } from '../../../../../services/events';
import { DealCardComponentNames } from '../../../../../services/events/componentNames';

export type UseOpenDealActionHandlerParams = {
  storeName?: string;
  dealUrl: string;
  cardPosition: number;
  dealId: string;
};

export default function useOpenDealActionHandler({
  storeName,
  dealUrl,
  cardPosition,
  dealId,
}: UseOpenDealActionHandlerParams) {
  const { push } = useRouter();

  const handleClickSeeDeal = () => push(dealUrl);

  return (action: DealAction | DealCardAction) => {
    switch (action) {
      case DealCardAction.ON_CARD_CLICK:
        emitGoToDealDetailsEvent(
          DealCardComponentNames.CardContent,
          dealId,
          cardPosition
        );
        push(dealUrl);
        break;
      case DealAction.CLICK_SEE_DEAL:
        emitGoToDealDetailsEvent(
          DealCardComponentNames.SeeDealButton,
          dealId,
          cardPosition
        );

        handleClickSeeDeal();
        break;
      case DealCardAction.ON_STORE_CLICK: {
        if (storeName) {
          emitTrackingEvent({
            category: EventCategory.GeneralDeal,
            name: GeneralDealEvents.ClickToGoToStorePage,
            extra: { store: storeName },
          });
        }

        break;
      }
      case DealAction.CLICK_COMMENTS:
        emitTrackingEvent({
          name: GeneralDealEvents.ClickToViewComments,
          category: EventCategory.GeneralDeal,
          extra: undefined,
        });
        emitGoToDealDetailsEvent(
          DealCardComponentNames.CommentsIconButton,
          dealId,
          cardPosition
        );

        break;
      case DealCardAction.ON_IMAGE_CLICK:
        emitGoToDealDetailsEvent(
          DealCardComponentNames.Image,
          dealId,
          cardPosition
        );
        push(dealUrl);

        break;
      case DealCardAction.ON_TITLE_CLICK:
        emitGoToDealDetailsEvent(
          DealCardComponentNames.Title,
          dealId,
          cardPosition
        );

        break;
      default:
        break;
    }
  };
}
