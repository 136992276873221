import { BodyHeavy, MediaQuery, SmallLight, Theme } from '@pelando/components';
import styled from 'styled-components';

export const SideBarIncentiveAppContainer = styled.div`
  display: none;

  @media ${MediaQuery.SCREEN_LG_UP} {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    border: 1px solid rgb(${Theme.colors.Golf});
    background-color: rgb(${Theme.colors.Juliet});
    grid-area: LoginSideBar;
    padding: 24px 16px;
    margin-bottom: 16px;
    text-align: center;
    align-items: center;
  }
`;

export const SideBarIncentiveAppTitle = styled.p`
  ${BodyHeavy}
  color: rgb(${Theme.colors.Brand});
  margin-bottom: 8px;
`;

export const SideBarIncentiveAppSubtitle = styled.p`
  ${SmallLight}
  padding: 0 12px;
  margin-bottom: 16px;
`;

export const SideBarIncentiveAppQrCode = styled.div`
  border: 1.5px solid rgb(${Theme.colors.Brand});
  border-radius: 10px;
  padding: 8px;

  &::before {
    content: '';
    height: 90px;
    width: 90px;
    display: block;
    background-image: url('/assets/feed/banner-qrcode.png');
    background-repeat: no-repeat;
    background-position: center;
  }
`;
