import React, { useEffect, useRef, useState } from 'react';

import { Button } from '@pelando/components';
import { IntersectionPoint } from './style';

export type LoadMoreProps = {
  onIntersecting: () => void;
  hasNextPage?: boolean;
  loading?: boolean;
};

const IntersectionWrapper = ({
  onIntersecting,
}: {
  onIntersecting: () => void;
}) => {
  const intersectionPoint = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!intersectionPoint.current) return undefined;

    const handleIntersection: IntersectionObserverCallback = (entries) => {
      const [entry] = entries;
      if (!entry) return;

      if (entry.isIntersecting) {
        onIntersecting();
      }
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0,
      rootMargin: '64px',
    });

    observer.observe(intersectionPoint.current);

    return () => observer.disconnect();
  }, [onIntersecting]);

  return (
    <IntersectionPoint data-testid="load-more-node" ref={intersectionPoint} />
  );
};

export default function LoadMoreIntersection({
  hasNextPage,
  onIntersecting,
  loading,
}: LoadMoreProps) {
  const [isIntersectionObserverSuported, setIntersectionObserverSupported] =
    useState(false);

  const shouldCreateInterceptor =
    isIntersectionObserverSuported && hasNextPage && !loading;

  useEffect(() => {
    setIntersectionObserverSupported('IntersectionObserver' in window);
  }, []);

  if (!hasNextPage) {
    return null;
  }

  const fallback = loading ? null : (
    <Button onClick={onIntersecting}>carregar mais</Button>
  );

  return shouldCreateInterceptor ? (
    <IntersectionWrapper onIntersecting={onIntersecting} />
  ) : (
    fallback
  );
}
