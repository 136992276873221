import Script from 'next/script';
import { isAdPlacementDisabled } from '../../services/featureFlags';

export function AmazonPubServiceScript() {
  if (isAdPlacementDisabled()) return null;

  return (
    <Script
      id="amazonpubservicetag"
      key="amazonpubservicetag"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
          !function(a9,a,p,s,t,A,g){if(a[a9])return;function q(c,r){a[a9]._Q.push([c,r])}a[a9]={init:function(){q("i",arguments)},fetchBids:function(){q("f",arguments)},setDisplayBids:function(){},targetingKeys:function(){return[]},_Q:[]};A=p.createElement(s);A.async=!0;A.src=t;g=p.getElementsByTagName(s)[0];g.parentNode.insertBefore(A,g)}("apstag",window,document,"script","//c.amazon-adsystem.com/aax2/apstag.js");
          window.apstag.init({  pubID: "${'152a942d-d891-4158-85e4-24b0ce6cdfa7'}",  adServer: "googletag" })
        `,
      }}
    />
  );
}
