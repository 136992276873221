import { getTranslatedString } from '@/locales';
import {
  OfferSortOption,
  ProductSortOptions,
} from '../../../domain/entities/Filter';
import { OfferStatus } from '../../../domain/entities/Offer';
import { PriceStatus } from '../../../domain/entities/Product';
import {
  FederatedSearchSort,
  FederatedSearchSortOrder,
} from '../../../infra/api/search/types/FederatedSearch';
import {
  Facet,
  FacetType,
  Filter,
  MultipleTextFacet,
  RangeFacet,
  TextFacet,
  TextFacetOptions,
} from '../../../infra/api/search/types/shared';
import { FilterMap } from '../../pages/public/Search/types';

export const PRODUCTS_SECTION_GRID_LIMIT = 8;
export const PRODUCTS_SECTION_LIST_LIMIT = 3;
export const STORES_SECTION_LIMIT = 2;

export const isRangeFacet = (facet: Facet): facet is RangeFacet =>
  facet.type === FacetType.RANGE;

export const isMutipleTextFacet = (facet: Facet): facet is MultipleTextFacet =>
  facet.type === FacetType.MULTIPLE_TEXT;

export const isTextFacet = (facet: Facet): facet is TextFacet =>
  facet.type === FacetType.TEXT;

export const FACETS_ON_RESULT_LIMIT = 4;

export const getFederatedSearchSortByDealSortOption = (
  sort?: OfferSortOption
): FederatedSearchSort | undefined => {
  if (!sort) return undefined;

  return {
    [OfferSortOption.COMMENT]: {
      by: 'lastCommentedAt',
    },
    [OfferSortOption.CREATED_AT]: {
      by: 'createdAt',
    },
    [OfferSortOption.TEMPERATURE]: {
      by: 'temperature',
    },
    [OfferSortOption.RELEVANCE]: undefined,
  }[sort];
};

export const getFederatedSearchSortByProductSortOption = (
  sort?: ProductSortOptions
): FederatedSearchSort | undefined => {
  if (!sort) return undefined;

  return {
    [ProductSortOptions.Recents]: {
      by: 'createdAt',
    },
    [ProductSortOptions.PriceLowToHigh]: {
      by: 'currentMinPrice',
      order: FederatedSearchSortOrder.ASC,
    },
    [ProductSortOptions.PriceHighToLow]: {
      by: 'currentMinPrice',
      order: FederatedSearchSortOrder.DESC,
    },
  }[sort];
};

export const getFederatedSearchFilter = (hideExpired?: boolean) => {
  if (!hideExpired) return undefined;

  return [
    {
      field: 'status',
      value: {
        text: ['active'],
      },
    },
  ];
};

export const getSearchDealsFilters = (hideExpired?: boolean) => {
  if (!hideExpired) return undefined;

  return {
    status: OfferStatus.ACTIVE,
  };
};

export const getStatusFacetOptions = (
  textFacets: TextFacet[]
): TextFacetOptions | undefined => {
  const statusFacet = textFacets.find((facet) => facet.field === 'status');
  return statusFacet?.options;
};

export const getHotStatusCount = (
  facetOptions: TextFacetOptions | undefined
): number => {
  const hotStatusOption = facetOptions?.values.find(
    ({ value }) => value === (PriceStatus.HOT as string)
  );
  return hotStatusOption?.count ?? 0;
};

export const parseFilters = (filters: FilterMap): Filter[] =>
  Array.from(filters, ([field, value]) => ({ field, value }));

export function getSearchTitle(term: string) {
  return getTranslatedString('search', 'search-page-title', term);
}

export function getSearchDescription(term: string) {
  return getTranslatedString('search', 'search-page-description', term);
}

export function getSearchTitleDescription(
  term: string,
  type: 'title' | 'description'
) {
  if (type === 'title') {
    return getTranslatedString('search', 'search-page-title', term);
  }
  if (type === 'description') {
    return getTranslatedString('search', 'search-page-description', term);
  }
  return undefined;
}

export const getFromSearchPage = (
  previousPage: string | null,
  locale: string
): boolean => {
  const pathLocale = locale === 'pt-br' ? '/busca' : '/s';
  return previousPage ? previousPage.includes(pathLocale) : false;
};
