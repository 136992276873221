export const DEAL_DETAIL_VISIT_MUTATION = `
mutation DealDetailVisit ($offerId: String!, $recommId: String) {
    public {
        dealDetailVisit(
            offerId: $offerId
            recommId: $recommId
        )
    }
}
`;

export default DEAL_DETAIL_VISIT_MUTATION;
