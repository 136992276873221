import { BASE_COMMENT_FRAGMENT } from '../../comments/fragments/baseComment';

export const PRODUCT_COMMENTS_QUERY = `
  query ProductCommentsQuery($productId: ID!, $limit: Int) {
    public {
      topProductComments(productId: $productId, limit: $limit) {
        ...BaseCommentFragment

        replyTo {
          ...BaseCommentFragment
        }
      }
    }
  }

  ${BASE_COMMENT_FRAGMENT}

`;
