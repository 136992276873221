import { HTMLAttributes, memo, useEffect, useState } from 'react';
import { isAdPlacementDisabled } from '../../services/featureFlags';
import {
  getGooglePubTag,
  GooglePubAdsScript,
  GooglePubTag,
} from '../GooglePubAdsScript';
import { useRefreshAd, UseRefreshAdParams } from './hooks/useRefreshAd';
import { useSetupSlot, UseSetupSlotParams } from './hooks/useSetupSlot';
import { Container } from './styles';
import { AmazonPubServiceScript } from '../AmazonPubServiceScript';

export type AdPlacementProps = HTMLAttributes<HTMLDivElement> &
  Pick<UseRefreshAdParams, 'preventRefresh' | 'slotCode'> &
  Pick<UseSetupSlotParams, 'customTargeting' | 'slotCode'>;

export const AdPlacement = memo(
  ({
    slotCode,
    customTargeting,
    preventRefresh,
    ...htmlProps
  }: AdPlacementProps) => {
    const [pubAds, setPubAds] = useState<GooglePubTag>();

    const slot = useSetupSlot({ slotCode, customTargeting, pubAds });
    const slotId = slot?.getSlotElementId() || '';

    useRefreshAd({ slotCode, preventRefresh, pubAds });

    useEffect(() => {
      getGooglePubTag().then(setPubAds);
    }, []);

    if (isAdPlacementDisabled()) {
      return null;
    }

    return (
      <>
        <GooglePubAdsScript />
        <AmazonPubServiceScript />
        <Container {...htmlProps} id={slotId} />
      </>
    );
  }
);
