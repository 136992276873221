import {
  BodyLight,
  Button,
  CaptionHeavy,
  Colors,
  H2Heavy,
  Icon,
  MediaQuery,
  Theme,
} from '@pelando/components';
import styled from 'styled-components';

type IconAlertProps = {
  $success: boolean;
};

export const IconTitle = styled(Icon)`
  color: rgb(${Theme.colors.Brand});
  font-size: 48px;
  margin-bottom: 24px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    margin-bottom: 16px;
  }
`;

export const Title = styled.h3`
  ${H2Heavy}
  margin-bottom: 8px;
  width: 343px;
`;

export const Subtitle = styled.p`
  ${BodyLight}
  color: rgb(${Colors.Gray});
  margin-bottom: 24px;
  text-align: center;
  width: 343px;
`;

export const ModalButton = styled(Button)`
  display: block;
  width: 100%;
  margin-top: 16px;
  height: 48px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 4px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    margin: 0 56px;
  }
`;

export const AlertContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(${Theme.colors.India});
  border: 1px solid rgba(233, 234, 237, 1);
  height: 48px;
  padding: 16px 40px 16px 16px;
  border-radius: 8px;
  gap: 8px;
`;

export const IconAlert = styled(Icon)<IconAlertProps>`
  color: ${({ $success }) =>
    $success ? `rgb(${Colors.Green})` : `rgb(${Theme.colors.Brand})`};
  font-size: 24px;
`;

export const AlertText = styled.span`
  ${CaptionHeavy}
`;
