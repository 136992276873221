import {
  copyToClipboard,
  DealAction,
  getPriceString,
} from '@pelando/components';
import {
  EventCategory,
  GeneralDealEvents,
} from '../../../../../../domain/events/analytics';
import { emitTrackingEvent } from '../../../../../../infra/analytics/emitTrackingEvent';

import {
  getUtmShareUrl,
  ScreenShareDeal,
  shareDealMobile,
  SourceShareDeal,
} from '../../../../../pages/public/Deal/services/share';
import { getAbsoluteClientUrl, getDealUrl } from '../../../../../services/urls';
import { Deal } from '../../../../../../domain/entities/Offer';

export type useCardActionHandlerProps = {
  deal: Pick<Deal, 'id' | 'title' | 'kind' | 'status' | 'price'>;
};

export default function useCardActionHandler({
  deal,
}: useCardActionHandlerProps) {
  const dealPriceString = deal.price ? `R$${getPriceString(deal.price)}` : '';

  const dealUrl =
    getAbsoluteClientUrl() +
    getDealUrl({
      id: deal.id,
      title: deal.title,
    }) +
    getUtmShareUrl(SourceShareDeal.home, ScreenShareDeal.desktop);

  const whatsappShareUrl = `https://api.whatsapp.com/send?text=${deal.title}+${dealPriceString}+${dealUrl}`;

  return (action: DealAction) => {
    switch (action) {
      case DealAction.CLICK_TO_SHARE:
        emitTrackingEvent({
          category: EventCategory.GeneralDeal,
          name: GeneralDealEvents.ClickToShareDeal,
          extra: { deal_id: deal.id },
        });
        shareDealMobile(deal, SourceShareDeal.home);
        break;
      case DealAction.CLICK_TO_WHATSAPP_SHARE:
        window.open(whatsappShareUrl, '_blank');

        break;
      case DealAction.CLICK_TO_COPY_LINK:
        copyToClipboard(dealUrl);
        break;
      default:
        break;
    }
  };
}
