import { useCallback } from 'react';
import { BannerType, useModal, useToast } from '@pelando/components';
import { HEADER_HEIGHT } from '@/presentation/components/Header/styles';
import { useTranslation } from '../useTranslation';

const TOAST_DELAY = 500;

export const useSocialAuthToastError = () => {
  const { showToast } = useToast();
  const { closeModal } = useModal();
  const { t } = useTranslation('login');

  const showSocialAuthToastError = useCallback(() => {
    closeModal();

    setTimeout(() => {
      showToast({
        bannerType: BannerType.ERROR,
        topPosition: HEADER_HEIGHT,
        titleText: t('social-auth-error-toast-title'),
        text: t('social-auth-error-toast-text'),
      });
    }, TOAST_DELAY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    showSocialAuthToastError,
  };
};
