import { PRODUCT_IMAGE_FRAGMENT } from './shared';

export const PRODUCT_QUERY = `
  query ProductQuery($id: ID!) {
    public {
      product: productV1(id: $id) {
        id
        title
        image {
          ...ProductImageFragment
        }
        indexSource
        enableIndexing
        canonicalUrl
        description
        specs {
          name
          value
        }
        categoryId
        categoryName
        currentMinPrice
        currentMaxPrice
        rangePercentage30Days
        minPrice30Days
        minPriceStore {
          id
          name
          slug
          image {
            id
            url(width: 64)
          }
        }
        maxPrice30Days
        rangePercentage90Days
        minPrice90Days
        maxPrice90Days
        nominalModel
        offerCount
        specs {
          name
          value
          type
        }
        specsSearchTerms {
          name
          term
        }  
        rangePercentageMessage {
          title
          subTitle
          description
          status
        }
        faq {
          data {
            question
            answer
          }
        }
        autoGeneratedDescription {
          description
          specsDescription
        }
      }
    }
  }

  ${PRODUCT_IMAGE_FRAGMENT}
`;
