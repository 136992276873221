import { OfferStatus } from '../../../../domain/entities/Offer';

export const DEFAULT_FEED_QUERIES_LIMIT = 30;

export type OfferQueryPageInfo = {
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
  endCursor?: string;
  startCursor?: string;
};

export type OffsetBasedPageInfo = {
  page: number;
  total: number;
  totalPages: number;
};

export type OfferFilterOptions = {
  filters?: { status: OfferStatus; communityIds?: string[] };
};

export type DefaultFeedVariables = {
  limit?: number;
  after?: string;
  page?: number;
};

export enum FeedTabSlug {
  HOTTEST = 'mais-quentes',
  RECENT = 'recentes',
  COMMENTED = 'comentadas',
  FEATURED = 'destaques',
}

export enum CategoryFeedTabSlug {
  CATEGORY = 'category',
}

export enum CommunityFeedTabSlug {
  COMMUNITY = 'community',
}

export enum FeedQueries {
  COMMENTED = 'getCommentedOffers',
  FEATURED = 'getFeaturedOffers',
  HOTTEST = 'getHottestFeedOffers',
  RECENT = 'getRecentsOffers',
  RECOMBEE = 'getRecommendedPrivateOffers',
}
