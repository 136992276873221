export const EXPIRE_OFFER_MUTATION = `
  mutation ExpireOfferMutation($id: ID!) {
    me {
      expireOffer(id: $id) {
        id
        status
      }
    }
  }
`;
