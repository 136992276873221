import { useTranslation } from '@/presentation/hooks/useTranslation';
import {
  SideBarIncentiveAppContainer,
  SideBarIncentiveAppQrCode,
  SideBarIncentiveAppSubtitle,
  SideBarIncentiveAppTitle,
} from './style';

export default function SidebarIcentiveApp() {
  const { t } = useTranslation('feed');
  return (
    <SideBarIncentiveAppContainer>
      <SideBarIncentiveAppTitle>
        {t('sidebar-incentive-app-title')}
      </SideBarIncentiveAppTitle>
      <SideBarIncentiveAppSubtitle>
        {t('sidebar-incentive-app-subtitle')}
      </SideBarIncentiveAppSubtitle>
      <SideBarIncentiveAppQrCode />
    </SideBarIncentiveAppContainer>
  );
}
