export enum ApplicationBuildKind {
  WEB = 'web',
  MOBILE_APP = 'mobile_app',
}

export enum MetadataClient {
  SiteMobile = 'site_mobile',
  SiteDesktop = 'site_desktop',
  AppIphone = 'app_iphone',
  AppAndroid = 'app_android',
}

export const isMobileBrowser = () => {
  const navigatorRegex =
    /(Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone)/gi;

  return !!window.navigator.userAgent.match(navigatorRegex);
};

export const getMetadataClient = () =>
  isMobileBrowser() ? MetadataClient.SiteMobile : MetadataClient.SiteDesktop;
