export const UPDATE_OFFER_STATUS_MUTATION = `
  mutation UpdateOfferStatusMutation(
    $id: ID!
    $input: backofficeUpdateOfferStatusInput!
  ) {
    backoffice {
      updateOfferStatus(id: $id, input: $input) {
        id
        status
      }
    }
  }
`;

export const UPDATE_OFFER_IS_PICKED_MUTATION = `
  mutation UpdateOfferIsPickedMutation($id: ID!, $isPicked: Boolean!) {
    backoffice {
      updateOfferIsPicked(id: $id, isPicked: $isPicked) {
        id
        isPicked
      }
    }
  }
`;

export const UPDATE_OFFER_LOCKED_COMMENTS_MUTATION = `
  mutation UpdateOfferLockedCommentsMutation(
    $id: ID!
    $lockedComments: Boolean!
  ) {
    backoffice {
      updateOfferLockedComments(id: $id, lockedComments: $lockedComments) {
        id
        lockedComments
      }
    }
  }
`;

export const UPDATE_OFFER_IS_TRACKED_MUTATION = `
  mutation UpdateOfferIsTrackedMutation($id: ID!, $isTracked: Boolean!) {
    backoffice {
      updateOfferIsTracked(id: $id, isTracked: $isTracked) {
        id
        isTracked
      }
    }
  }
`;

export const UPDATE_OFFER_EXPIRE_LOCK_MUTATION = `
  mutation UpdateOfferExpireLockMutation($id: ID!, $expireLock: Boolean!) {
    backoffice {
      updateOfferExpireLock(id: $id, expireLock: $expireLock) {
        id
        expireLock
      }
    }
  }
`;

export const SEND_TO_TELEGRAM_MUTATION = `
  mutation SendToTelegramMutation($id: ID!) {
    me {
      sendOfferToTelegram(id: $id)
    }
  }
`;
