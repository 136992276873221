import {
  BodyHeavy,
  Button,
  Colors,
  Icon,
  MediaQuery,
  SmallLight,
  Theme,
} from '@pelando/components';
import { styled } from 'styled-components';

export const LoginSideBarContainer = styled.div`
  display: none;

  @media ${MediaQuery.SCREEN_LG_UP} {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    border: 1px solid rgb(${Theme.colors.Golf});
    background-color: rgb(${Theme.colors.Juliet});
    grid-area: LoginSideBar;
    padding: 24px 16px;
    margin-bottom: 16px;
    text-align: center;
  }
`;

export const LoginSideBarTitle = styled.p`
  ${BodyHeavy}
  padding: 0 13px;
  margin-bottom: 16px;
`;

export const LoginSideBarButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
`;

export const LoginSideBarButton = styled(Button)`
  height: 48px;
  width: 100%;
  padding: 8px 28px;
  border-radius: 12px;
  &:nth-child(2) {
    margin: 0 8px;
  }
`;

export const LoginSideBarIcon = styled(Icon)`
  color: rgb(${Theme.colors.Alpha});
  font-size: 24px;
`;

export const TermsOfUse = styled.div`
  ${SmallLight}
  color: rgb(${Colors.Gray});
  margin-top: 16px;
  padding: 0 20px;

  a {
    color: rgb(${Colors.Gray});
  }
`;
