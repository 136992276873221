import { PRODUCT_IMAGE_FRAGMENT } from './shared';

export const TOP_PRODUCTS_BY_MODEL_QUERY = `
  query GetTopProductsByModel {
    public {
      topProductsByModel {
        edges {
          model
          image {
            id
            url
          }
          products {
            id
            title
            nominalModel,
            image {
              ...ProductImageFragment
            }
            totalStoresCount
            rangePercentageMessage {
              status
            }
            categoryId
            currentMinPrice
          }
        }
      }
    }
  }

  ${PRODUCT_IMAGE_FRAGMENT}
`;
