export type RangeFacetOptions = {
  from: number;
  to: number;
};

type TextFacetOptionValue = {
  count: number;
  value: string;
};

export type TextFacetOptions = {
  values: TextFacetOptionValue[];
};

export type FacetOptions = TextFacetOptions | RangeFacetOptions;

export enum Field {
  SpecificationsBrand = 'specifications.brand',
  SpecificationsColor = 'specifications.color',
  CategoryName = 'categoryName',
}

export type FacetField = Field | string;

export enum FacetType {
  TEXT = 'TEXT',
  MULTIPLE_TEXT = 'MULTIPLE_TEXT',
  RANGE = 'RANGE',
}

export type Facet = {
  type: FacetType;
  name: string;
  field: FacetField;
  options: FacetOptions;
};

export type MultipleTextFacet = Facet & {
  type: FacetType.MULTIPLE_TEXT;
  options: TextFacetOptions;
};

export type RangeFacet = Facet & {
  type: FacetType.RANGE;
  options: RangeFacetOptions;
};

export type TextFacet = Facet & {
  type: FacetType.TEXT;
  options: TextFacetOptions;
};

export type FilterValue = {
  from?: number;
  to?: number;
  text?: string[];
};

export type Filter = {
  field: string;
  value: FilterValue;
};
