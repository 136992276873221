const prefix = '@products';

export const ProductsActionType = {
  GetProduct: `${prefix}/GET_PRODUCT`,
  GetProductComments: `${prefix}/GET_PRODUCT_COMMENTS`,
  GetRelatedProducts: `${prefix}/GET_RELATED_PRODUCTS`,
  GetProductDeals: `${prefix}/GET_PRODUCT_DEALS`,
  GetProductPriceHistory: `${prefix}/GET_PRODUCT_PRICE_HISTORY`,
  GetAssociatedDeals: `${prefix}/GET_ASSOCIATED_OFFERS`,
  GetTopProductsByCategory: `${prefix}/GET_TOP_PRODUCTS_BY_CATEGORY`,
  GetTopProductsByModel: `${prefix}/GET_TOP_PRODUCTS_BY_MODEL`,
  GetProductsSuggestions: `${prefix}/GET_PRODUCTS_SUGGESTIONS`,
  GetProductVariants: `${prefix}/GET_PRODUCTS_VARIANTS`,
} as const;
