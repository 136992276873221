import { HeaderAlert, HeaderFeaturedComment } from '@pelando/components';
import { useRouter } from 'next/router';
import { DealDetails } from '../..';
import {
  GeneralDealEvents,
  EventCategory,
} from '../../../../../../domain/events/analytics';
import { emitTrackingEvent } from '../../../../../../infra/analytics/emitTrackingEvent';
import { getDealCardVerifiedAuthorHeader } from '../../../../../services/deals/getDealCardVerifiedAuthorHeader';
import { emitGoToDealDetailsEvent } from '../../../../../services/events';
import { DealCardComponentNames } from '../../../../../services/events/componentNames';
import {
  SpecialFeedTabSlugPtBR,
  SpecialFeedTabSlugEnUS,
} from '../../../../../services/feedTabsCategories';
import { getDealUrlWithComment } from '../../../../../services/urls';

export type useHeaderContentHandlerProps = {
  deal: DealDetails;
  categorySlug?: string;
  position: number;
};

export default function useHeaderContentHandler({
  deal,
  categorySlug,
  position,
}: useHeaderContentHandlerProps) {
  const { push } = useRouter();
  const { user: author } = deal;

  const getFeaturedCommentHeader = () =>
    !!deal.lastComment && (
      <HeaderFeaturedComment
        authorNickname={deal.lastComment.author.nickname}
        commentId={deal.lastComment.id}
        authorImage={deal.lastComment.author.image?.url}
        content={deal.lastComment.content}
        onClick={(commentId) => {
          push(
            getDealUrlWithComment({
              dealId: deal.id,
              commentId,
              title: deal.title,
            })
          );
          emitTrackingEvent({
            name: GeneralDealEvents.ClickToViewComments,
            category: EventCategory.GeneralDeal,
            extra: undefined,
          });
          emitGoToDealDetailsEvent(
            DealCardComponentNames.HeaderComment,
            deal.id,
            position
          );
        }}
        isVerified={deal.lastComment.author.isVerified}
      />
    );

  const getKeywordAlertHeader = () => (
    <HeaderAlert key={deal.id} matchedKeyword={deal.keyword} />
  );

  if (
    !!deal.lastComment &&
    (categorySlug === SpecialFeedTabSlugPtBR.COMMENTED ||
      categorySlug === SpecialFeedTabSlugEnUS.COMMENTED)
  ) {
    return getFeaturedCommentHeader();
  }

  if (deal.keyword) {
    return getKeywordAlertHeader();
  }

  if (author) {
    return getDealCardVerifiedAuthorHeader(author);
  }

  return undefined;
}
