import { PRODUCT_IMAGE_FRAGMENT } from '@/infra/api/products/queries/shared';

export const PRODUCTS_SUGGESTIONS_QUERY = `
  query ProductsSuggestionQuery($query: String!, $limit: Int) {
    public {
      productSuggestions(query: $query, limit: $limit) {
        id
        title
        image {
          ...ProductImageFragment
        }
        totalStoresCount
        rangePercentageMessage {
          status
        }
        categoryId
        currentMinPrice
      }
    }
  }

  ${PRODUCT_IMAGE_FRAGMENT}
`;
