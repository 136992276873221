import { DealCardDefault, DealCardSkeleton, Theme } from '@pelando/components';
import styled from 'styled-components';
import { skeletonItemAnimationDelay } from '../../../styles/skeleton';

export const DelayedDealListCardSkeleton = styled(DealCardSkeleton)<{
  cardIndex: number;
  cardsLength: number;
}>`
  margin-bottom: 8px;
  background-color: rgb(${Theme.colors.Juliet});
  ${({ cardIndex, cardsLength }) =>
    skeletonItemAnimationDelay(cardIndex, cardsLength)}
`;

// force font-size to prevent styled-components bug class order
export const Card = styled(DealCardDefault)`
  && [data-name='hexagon-check'] {
    font-size: 24px;
  }
`;

export const Title = styled.h2`
  display: contents;
  font-size: inherit;
`;
