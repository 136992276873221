export const DEAL_TIMESTAMPS_FRAGMENT = `
  fragment DealTimestampsFragment on OfferTimestamps {
    firstApprovedAt
    approvedAt
    createdAt
    pickedAt
    lastCommentedAt
    publishAt
  }
`;

export const DEAL_TIP_FRAGMENT = `
  fragment DealTipFragment on Tip {
    title
    description
    smallIconUrl: iconUrl(height: 36)
  }
`;
