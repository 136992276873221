import {
  DEAL_TIMESTAMPS_FRAGMENT,
  DEAL_TIP_FRAGMENT,
} from '../../fragments/deal';
import { CASHBACK_FRAGMENT_OFFER } from '../../fragments/cashback';

export const OFFER_QUERY = `
  query OfferQuery($id: ID!) {
    public {
      offer(id: $id) {
        id
        body: content
        noIndex
        commentCount
        couponCode
        discountFixed
        discountPercentage
        freeShipping
        associatedProduct {
          id
          title
          rangePercentage90Days
          currentMinPrice
          rangePercentageMessage {
            title
            subTitle
            description
            status
          }
        }
        image {
          id
          url(height: 406)
        }
        images {
          id
          url(height: 406)
        }
        isLocal
        isFirstOffer
        isPicked
        isTracked
        expireLock
        lockedComments
        kind
        price
        status
        temperature
        title
        sourceUrl
        isTracked
        hideInFeed
        genderPreference
        tip {
          ...DealTipFragment
        }

        deletionReasons {
          id
          title
          link
          bannerTitle
          description
        }

        timestamps {
          ...DealTimestampsFragment
        }
        
        ...CashbackFragmentOffer
        

        category {
          id
          slug
          title
          communities {
            id
            name
            color
            image
            genderPreference
            slug
          }
        }

        user {
          id
          image {
            id
            url(width: 64, height: 64)
          }
          nickname
          isVerified
        }

        store {
          id
          name
          slug
          url
          image {
            id
            url(height: 406)
          }
        }

        genderPreference
        autoGeneratedDescription
      }
    }
  }

   ${CASHBACK_FRAGMENT_OFFER}

  ${DEAL_TIMESTAMPS_FRAGMENT}
  ${DEAL_TIP_FRAGMENT}
`;
