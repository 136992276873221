import { useEffect, useMemo, useRef, useState } from 'react';
import { GooglePubTag } from '../../../GooglePubAdsScript';
import { SlotCode, SlotSizes, TargetingKey } from '../../constants';

export type UseSetupSlotParams = {
  slotCode: SlotCode;
  pubAds?: GooglePubTag;
  customTargeting?: string | string[];
};

const getUrlData = () =>
  window.location.pathname === '/'
    ? ['/']
    : window.location.pathname.replace(/-/g, '/').split('/').filter(Boolean);

export function useSetupSlot({
  slotCode,
  pubAds,
  customTargeting,
}: UseSetupSlotParams) {
  const isMounted = useRef(false);
  const [slot, setSlot] = useState<googletag.Slot>();

  const sizes = useMemo(() => SlotSizes[slotCode], [slotCode]);

  const sizesWithoutViewport = useMemo(
    () =>
      sizes.reduce<googletag.MultiSize>(
        (prev, curr) => prev.concat(curr.adSizes),
        []
      ),
    [sizes]
  );

  const buildedSizes = useMemo(() => {
    const mapping = pubAds?.sizeMapping();
    sizes.forEach(({ viewport, adSizes }) => {
      mapping?.addSize(viewport, adSizes);
    });

    return mapping?.build() || [];
  }, [sizes, pubAds]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  // setup slot
  useEffect(() => {
    if (!pubAds) return;
    pubAds.cmd.push(() => {
      const newSlot = pubAds
        .defineSlot(slotCode, sizesWithoutViewport)
        ?.addService(pubAds.pubads())
        .setTargeting(TargetingKey.URL_DATA, getUrlData())
        .setTargeting(TargetingKey.VIEWPORT_WIDTH, `${window.innerWidth}`)
        .setTargeting(TargetingKey.VIEWPORT_HEIGHT, `${window.innerHeight}`);

      if (customTargeting) {
        newSlot?.setTargeting(TargetingKey.TAG_OF_PAGE, customTargeting);
      }

      newSlot?.defineSizeMapping(buildedSizes);

      setSlot(newSlot);

      pubAds.enableServices();
    });
  }, [buildedSizes, customTargeting, pubAds, sizesWithoutViewport, slotCode]);

  // show ad
  useEffect(() => {
    if (!pubAds || !slot) return undefined;

    window.apstag.fetchBids(
      {
        slots: [
          {
            sizes: sizesWithoutViewport as unknown as number[][],
            slotID: slot.getSlotElementId(),
            slotName: slot.getAdUnitPath(),
          },
        ],
      },
      () => {
        pubAds.cmd.push(() => {
          setTimeout(() => {
            pubAds.display(slot.getSlotElementId());
            window.apstag.setDisplayBids();
          }, 300);
        });
      }
    );

    return () => {
      pubAds.cmd.push(() => {
        pubAds.destroySlots([slot]);
      });
    };
  }, [pubAds, slot, sizesWithoutViewport]);

  return slot;
}
