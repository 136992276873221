import { css } from 'styled-components';

const SKELETON_FADE_DURATION_SECONDS = 0.75;

export const skeletonItemAnimationDelayString = (
  skeletonItemIndex: number,
  skeletonListSize: number
) => `
  animation-delay: ${
    (SKELETON_FADE_DURATION_SECONDS * skeletonItemIndex) / skeletonListSize
  }s;
`;

export const skeletonItemAnimationDelay = (
  skeletonItemIndex: number,
  skeletonListSize: number
) => css`
  ${skeletonItemAnimationDelayString(skeletonItemIndex, skeletonListSize)}
`;
