export async function getFileFromImageUrl(url?: string) {
  if (!url) return undefined;

  try {
    const response = await fetch(url);
    const data = await response.blob();
    const metadata = {
      // We default to image/jpeg but could try getting the file extension
      // the main goal is to not let it fail
      type: response.headers.get('Content-Type') || 'image/jpeg',
    };
    return new File([data], 'offer-image.jpg', metadata);
  } catch (e) {
    return undefined;
  }
}

export function getImagePath(locale: string, imageName: string): string {
  const folderName = locale === 'en-US' ? 'en-us' : 'pt-br';

  const imagePath = `/assets/${folderName}/${imageName}`;

  return imagePath;
}
